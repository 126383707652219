import { TextStyle } from 'pixi.js';

const font = 'Arial';
export const buyBonusTitleStyle = new TextStyle({
  fontSize: 65,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: '500',
  fill: 'white',
  dropShadow: true,
  dropShadowAngle: 0,
  dropShadowBlur: 1,
  stroke: 'gray',
  strokeThickness: 1,
  lineJoin: 'round',
});

export const buyBonusTitleStyleB = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowDistance: 0,
  fill: '#1e400a',
  fillGradientType: 1,
  fontFamily: 'Noto Sans',
  fontSize: 65,
  fontWeight: 'bold',
  miterLimit: 5,
  padding: 5,
  whiteSpace: 'normal',
  wordWrapWidth: 140,
});

export const buyBonusSubTitleStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: 2,
  dropShadowColor: '#371a06',
  dropShadowDistance: 3,
  fill: ['#fecc86', '#fbf1d5', '#996e3d', '#a85a00', '#f9be3e', '#64340c'],
  fillGradientStops: [0.3],
  fontFamily: 'Noto Sans',
  fontSize: 85,
  stroke: '#663100',
  strokeThickness: 1,
  whiteSpace: 'normal',
});

export const buyBonusTitleStyleC = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#f5c717',
  dropShadowDistance: 4,
  fill: '#7d0810',
  fontFamily: 'Noto Sans',
  fontSize: 45,
  stroke: '#f5c717',
  strokeThickness: 2,
  whiteSpace: 'normal',
});

export const buyBonusTotalCost = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#f5c717',
  dropShadowDistance: 4,
  fill: '#7d0810',
  fontFamily: 'Noto Sans',
  fontSize: 45,
  stroke: '#f5c717',
  strokeThickness: 5,
  whiteSpace: 'normal',
});

export const totalCostTextStyle = new TextStyle({
  fontSize: 70,
  fontFamily: font,
  whiteSpace: 'normal',
  fontWeight: 'bold',
  fill: ['#0088ff', '#0000ff', '#000088'],
  fillGradientStops: [0.25, 0.5, 0.75],
  fillGradientType: 0,
  stroke: '#FFFFFF',
  strokeThickness: 3,
  dropShadow: true,
  dropShadowColor: 0x000000,
  dropShadowAlpha: 1,
  dropShadowAngle: 0.785,
  dropShadowBlur: 4,
  dropShadowDistance: 2,
  lineJoin: 'round',
});

export const totalCostValueTextStyle = new TextStyle({
  align: 'center',
  dropShadow: true,
  dropShadowAngle: -1.2,
  dropShadowBlur: 9,
  dropShadowColor: '#f5c717',
  dropShadowDistance: 0,
  fill: '#7d0810',
  fontFamily: 'Noto Sans',
  fontSize: 95,
  stroke: '#f5c717',
  strokeThickness: 2,
  whiteSpace: 'normal',
});

export const titleBuyBonusTextStyles = new TextStyle({
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  fontWeight: '900',
  lineJoin: 'round',
});

export const buyBonusBetTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFFFFF',
  fontFamily: 'Noto Sans',
  fontSize: 28,
  fontWeight: '700',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusBetValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF7AE',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  fontWeight: '900',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusTotalCostTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFFFFF',
  fontFamily: 'Noto Sans',
  fontSize: 28,
  fontWeight: '700',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});

export const buyBonusTotalCostValueTextStyle = new TextStyle({
  align: 'center',
  fill: '#FFF7AE',
  fontFamily: 'Noto Sans',
  fontSize: 40,
  fontWeight: '900',
  stroke: '#464646',
  strokeThickness: 8,
  whiteSpace: 'normal',
  lineJoin: 'round',
});
