import type { RemoteStorage } from '@money.energy/setting-store-client';
import { SlotId } from './config';
import { Icon, LineSet } from './game/d';
import { ReelSetType } from './gql/d';

export interface IUserBalance {
  id: string;
  clientId: string;
  balance: {
    amount: number;
    currency: string;
  };
}

export interface IAuthInput {
  clientId: string;
  token: string;
  slotId: string;
  lng: string;
  home: string;
}

export interface Payline {
  lineId: number | null;
  winPositions: number[];
  amount: number;
}
export interface ISettledBet {
  bet: {
    id: string;
    coinAmount: number;
    coinValue: number;
    slotId: string;
    result: {
      reelPositions: number[];
      winCoinAmount: number;
      spinResult: Icon[];
    };
    data: {
      bonuses: UserBonus[];
      features: {
        freeSpinsCount: number;
        isFreeSpins: boolean;
        mystery: {
          mysteryMatrix: MysteryMatrix[];
        };
      };
    };
    userBonusId: string;
    userBonus: {
      id: string;
      bonusId: string;
      lineSet: LineSet;
    };
    reelSetId: string;
    reelSet: ReelSet;
    lineSet: LineSet;
    createdAt: string;
    updatedAt: string;
  };
  paylines: Payline[];
  balance: {
    placed: {
      amount: number;
      currency: string;
    };
    settled: {
      amount: number;
      currency: string;
    };
  };
}

export enum BonusStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SETTLED = 'SETTLED',
}

export enum BonusTypes {
  FREE_SPIN = 'FREE_SPIN',
}

export type UserBonus = {
  bonusId: string;
  bonus: {
    type: string;
  };
  id: string;
  isActive: boolean;
  gameMode: GameMode;
  currentRound: number;
  betId: string;
  coinAmount: number;
  rounds: number;
  roundsPlayed: number;
  data: {
    freeSpinsFeature?: IFreeSpinFeature;
  };
  reelSetId?: string;
  totalWinAmount: number;
};

export interface IBonus {
  id: string;
  type: string;
  coinAmount: number;
  isActive: boolean;
  bonusId: string;
  roundsPlayed: number;
  currentRound: number;
  rounds: number;
  reelSetId: string;
  totalWinAmount: number;
  betId: string;
}

export type ReelSet = {
  id: string;
  layout: SlotId[][];
  type: ReelSetType;
  additionalReelSets: SlotId[][];
};

export type BuyBonusEnterProps = {
  bonusId: string;
};
export type FreeSpinsEnterProps = {
  bonus: UserBonus;
};
export type EnterProps = (BuyBonusEnterProps | FreeSpinsEnterProps) & {
  immediate?: boolean;
};

export enum ModalOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_BONUS = 'BUY_BONUS',
  NONE = null,
}

export enum GameMode {
  BASE_GAME,
  FREE_SPINS,
  BUY_BONUS,
}

export enum BonusState {
  FREE_SPINS,
}

export const baseReelSetIds = ['22de6860-6921-413d-916c-ad9835e08f44'];

export const bonusesId = {
  [GameMode.FREE_SPINS]: '9f915072-031a-4aca-9064-1dbf371f7ee4',
};

export const buyBonusBonusesId = {
  [BonusState.FREE_SPINS]: '31c771b4-7452-4452-9584-251a3a6ddda2',
};

export enum EventTypes {
  RESTORE_GAME = 'restoreGame',
  CHANGE_MODE = 'changeMode',
  COUNT_UP_END = 'countUpEnd',
  DISABLE_ALL_MINI_PAY_INFOS = 'disableAllMiniPayInfos',
  DISABLE_PAYINFO = 'disablePayInfo',
  END_TWEEN_ANIMATION = 'endTweenAnimation',
  ENABLE_PAYINFO = 'enablePayInfo',
  FORCE_STOP_REELS = 'forceStopReels',
  FORCE_RESIZE = 'forceResize',
  GAME_READY = 'gameReady',
  HIDE_COUNT_UP = 'hideCountUp',
  CHANGE_REELS_DATA = 'changeReelsData',
  HIDE_WIN_LABEL = 'hideWinLabel',
  HIDE_COUNT_UP = 'hideWinCountUpMessage',
  HANDLE_CHANGE_BET_AMOUNT = 'handleChangeBetAmount',
  HANDLE_SKIP_FADE_ANIMATION = 'handleSkipFadeAnimation',
  HANDLE_START_FADE_ANIMATION = 'handleStartFadeAnimation',
  HANDLE_BUY_BONUS = 'handleBuyBonus',
  HANDLE_CHANGE_RESTRICTION = 'handleChangeRestriction',
  SPACEKEY_CLOSE_MESSAGE_BANNER = 'spaceKeyCloseMessageBanner',
  REELS_STOPPED = 'reelsStopped',
  REEL_LANDED = 'reelLanded',
  REEL_LANDED_ANIMATION_PLAYED = 'reelLandedAnimationPlayed',
  RESIZE = 'resize',
  RESIZE_GAME_CONTAINER = 'resizeGameContainer',
  REMOVE_TWEEN_ANIMATION = 'removeTweenAnimation',
  ROLLBACK_REELS = 'rollbackReels',
  SET_SLOTS_VISIBILITY = 'setSlotsVisibility',
  START_TWEEN_ANIMATION = 'startTweenAnimation',
  START_WIN_ANIMATION = 'startWinAnimation',
  START_SPIN_ANIMATION = 'startSpinAnimation',
  START_MODE_CHANGE_FADE = 'startModeChangeFade',
  START_BUY_BONUS_ROUND = 'startBuyBonusRound',
  START_COUNT_UP = 'startCountUp',
  START_SPECIAL_WIN = 'startSpecialWin',
  SHOW_PAY_INFO = 'showPayInfo',
  SHOW_WIN_LABEL = 'showWinLabel',
  SOUND_INITIALIZED = 'soundInitialized',
  SET_CURRENT_RESULT_MINI_PAYINFO = 'setCurrentResultMiniPayInfo',
  SKIP_ALL_WIN_ANIMATIONS = 'skipAllWinAnimations',
  SKIP_WIN_COUNT_UP_ANIMATION = 'skipWinCountUpAnimation',
  SKIP_WIN_ANIMATION = 'skipWinAnimation',
  THROW_ERROR = 'throwError',
  UPDATE_BET = 'updateBet',
  UPDATE_USER_BALANCE = 'updateUserBalance',
  UPDATE_WIN_VALUE = 'updateWinValue',
  POST_RENDER = 'postrender',
  OPEN_POPUP = 'openPopup',
  OPEN_POPUP_BG = 'openPopupBg',
  CLOSE_POPUP = 'closePopup',
  CLOSE_POPUP_BG = 'closePopupBg',
  DISABLE_BUY_BONUS_BTN = 'disableBuyBonusBtn',
  SET_IS_LEFT_HAND_MODE = 'setIsLeftHandMode',
  SET_IS_AUTO_SPINS = 'setIsAutoSpins',
  SET_AUTO_SPINS_LEFT = 'setAutoSpinsLeft',
  SET_IS_SPIN_IN_PROGRESS = 'isSpinInProgress',
  SET_IS_SLOT_IS_BUSY = 'isSlotBusy',
  SET_IS_SLOTS_STOPPED = 'isSlotsStopped',
  TOGGLE_SOUND = 'toggleSound',
  TOGGLE_TURBO_SPIN = 'toggleTurboSpin',
  TOGGLE_SPIN = 'toggleSpin',
  HANDLE_DESTROY_INTRO_SCREEN = 'handleDestroyIntroScreen',
  WIN_LINE_ANIMATION_END = 'winLineAnimationEnd',
  SHOW_WIN_LINES = 'showWinLines',
  HIDE_WIN_LINES = 'hideWinLines',
  SHOW_TINT = 'showTint',
  FORCE_CLOSE_BUYBONUS = 'forceCloseBuyBonus',
  SHOW_COINS = 'showCoins',
  HIDE_COINS = 'hideCoins',
  END_SPECIAL_WIN = 'endSpecialWin',
  FORCE_STOP_AUTOPLAY = 'FORCE_STOP_AUTOPLAY',
  SET_IS_FREESPINS_WIN = 'SET_IS_FREESPINS_WIN',
  END_FREE_SPINS = 'END_FREE_SPINS',
  NEXT_FREE_SPINS_ROUND = 'NEXT_FREE_SPINS_ROUND',
  UPDATE_FREE_SPINS_AMOUNT = 'UPDATE_FREE_SPINS_AMOUNT',
  UPDATE_TOTAL_WIN_VALUE = 'UPDATE_TOTAL_WIN_VALUE',
  START_FREE_SPINS = 'START_FREE_SPINS',
  CREATE_FREESPIN_POPUP = 'CREATE_FREESPIN_POPUP',
  SHOW_BACKDROP = 'showBackDrop',
  HIDE_BACKDROP = 'hideBackDrop',
  MYSTERY_SYMBOLS_REVEAL_START = 'MYSTERY_SYMBOLS_REVEAL_START',
  MYSTERY_SYMBOLS_REVEAL_END = 'MYSTERY_SYMBOLS_REVEAL_END',
  TRANSITION_START = 'TRANSITION_START',
  TRANSITION_END = 'TRANSITION_END',
  DISABLE_BUY_BONUS_BTN = 'DISABLE_BUY_BONUS_BTN',
  UPDATE_BOTTOM_BAR_BET = 'UPDATE_BOTTOM_BAR_BET',
}

export enum GraphQLErrorsType {
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
}

declare global {
  interface Window {
    __ENV__: {
      ENV: string;
      STATIC_CDN_URL: string;
      STATIC_CDN_DIRECTORY: string;
      APP_VERSION: string;
      SENTRY_ENABLED: boolean;
      SENTRY_DSN: string;
    };
    __PIXI_APP__: PIXI.Application;
    eventEmitter: PIXI.utils.EventEmitter;
    remoteStorage: RemoteStorage;
  }
}

export enum PopupOpeningTypes {
  MENU = 'MENU',
  AUTOPLAY = 'AUTOPLAY',
  BET_SETTINGS = 'BET_SETTINGS',
  BUY_BONUS = 'BUY_BONUS',
  NONE = null,
  INFO = 'INFO',
  HISTORY = 'HISTORY',
}
