import { Flow } from '../../flow';
import { EventTypes, GameMode, PopupOpeningTypes } from '../../global.d';
import {
  // setIsOpenAutoplayPopup,
  // setIsOpenBetSettingsPopup,
  // setIsOpenHistoryPopup,
  setIsOpenInfoPopup,
  setIsPopupOpened,
  setIsPopupOpeningInProgress,
} from '../../gql/cache';
import {
  calcPercentage,
  isMobileLandscape,
  isMobilePortrait,
  isRegularMode,
  isTabletLandscape,
  isTabletPortrait,
} from '../../utils';
import {
  BTN_GAP_DESKTOP_H,
  BTN_GAP_DESKTOP_W,
  BTN_GAP_MOBILE_H,
  BTN_GAP_MOBILE_W,
  BTN_GAP_TABLET_H,
  BTN_GAP_TABLET_W,
  BTN_UI_DESKTOP_H,
  BTN_UI_DESKTOP_W,
  BTN_UI_MOBILE_H,
  BTN_UI_MOBILE_W,
  BTN_UI_TABLET_H,
  BTN_UI_TABLET_W,
  eventEmitter,
} from '../config';
import { UiButton } from '../ui/uiButton';

class InfoButton extends UiButton {
  private isAutoSpins = false;

  private isSpinInProgress = false;

  private isSlotBusy = false;

  constructor() {
    super('info');
    this.interactive = !this.isDisabled;
    this.on('click', () => this.handleClick());
    this.on('touchstart', () => this.handleClick());
    this.initSubscriptions();
    this.handleDisable();
  }

  protected override onGameModeChange(_settings: { mode: GameMode }): void {
    this.handleDisable();
  }

  private initSubscriptions = (): void => {
    eventEmitter.on(EventTypes.SET_IS_AUTO_SPINS, (isAutoSpins: boolean) => {
      this.isAutoSpins = isAutoSpins;
      this.handleDisable();
    });
    eventEmitter.on(EventTypes.OPEN_POPUP, () => {
      this.handleDisable();
    });
    eventEmitter.on(EventTypes.CLOSE_POPUP, () => {
      this.handleDisable();
    });
    eventEmitter.on(EventTypes.SET_IS_SPIN_IN_PROGRESS, (isSpinInProgress: boolean) => {
      this.isSpinInProgress = isSpinInProgress;
      this.handleDisable();
    });
    eventEmitter.on(EventTypes.SET_IS_SLOT_IS_BUSY, (isSlotBusy: boolean) => {
      this.isSlotBusy = isSlotBusy;
      this.handleDisable();
    });
  };

  private handleClick(): void {
    if (!this.isDisabled && setIsPopupOpeningInProgress() === PopupOpeningTypes.NONE) {
      setIsPopupOpeningInProgress(PopupOpeningTypes.INFO);

      setIsOpenInfoPopup(!setIsOpenInfoPopup());
      // setIsOpenHistoryPopup(false);
      // setIsOpenAutoplayPopup(false);
      // setIsOpenBetSettingsPopup(false);
      setIsPopupOpened(false);
      eventEmitter.emit(EventTypes.DISABLE_ALL_MINI_PAY_INFOS);
      setTimeout(() => setIsPopupOpeningInProgress(PopupOpeningTypes.NONE), 100);
    }
  }

  private handleDisable = (): void => {
    this.setDisable(
      !isRegularMode(Flow.the.controller.gameMode) ||
        this.isAutoSpins ||
        this.isSpinInProgress ||
        this.isSlotBusy ||
        setIsPopupOpened(),
    );
  };

  public override handlePosition(): void {
    this.btn.anchor.set(0, 0);
    const ratio = this.applicationSize.width / this.applicationSize.height > 2.15;
    let deviceSize = 0;
    let btnSize = ratio ? BTN_UI_DESKTOP_H : BTN_UI_DESKTOP_W;
    let btnGap = ratio ? BTN_GAP_DESKTOP_H : BTN_GAP_DESKTOP_W;

    if (isTabletLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_TABLET_H : BTN_UI_TABLET_W;
      btnGap = ratio ? BTN_GAP_TABLET_H : BTN_GAP_TABLET_W;
    }
    if (isTabletPortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_TABLET_W;
      btnGap = BTN_GAP_TABLET_W;
    }
    if (isMobileLandscape(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = ratio ? BTN_UI_MOBILE_H : BTN_UI_MOBILE_W;
      btnGap = ratio ? BTN_GAP_MOBILE_H : BTN_GAP_MOBILE_W;
    }
    if (isMobilePortrait(this.applicationSize.width, this.applicationSize.height)) {
      btnSize = BTN_UI_MOBILE_W;
      btnGap = BTN_GAP_MOBILE_W;
    }

    if (ratio) {
      deviceSize = this.applicationSize.height;
    } else {
      deviceSize = this.applicationSize.width;
    }

    if (this.isPortraitMode) {
      deviceSize = this.applicationSize.height;
    }

    const size = calcPercentage(deviceSize, btnSize);
    const gap = calcPercentage(deviceSize, btnGap);
    const x = gap + size + gap / 2;
    const y = gap;
    this.setSize(size);

    this.x = x;
    this.y = y;
  }
}

export default InfoButton;
