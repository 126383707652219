import type React from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18next';
import styles from './info.module.scss';

const Features: React.FC = () => {
  const { t } = useTranslation();
  const features = [
    { key: 'infoFeatures2_1', text: i18n.t('infoFeatures2_1') },
    { key: 'infoFeatures2_2', text: i18n.t('infoFeatures2_2') },
    { key: 'infoFeatures2_3', text: i18n.t('infoFeatures2_3') },
    { key: 'infoFeatures2_4', text: i18n.t('infoFeatures2_4') },
    { key: 'infoFeatures2_5', text: i18n.t('infoFeatures2_5') },
    { key: 'infoFeatures2_6', text: i18n.t('infoFeatures2_6') },
  ];

  return (
    <div className={styles['gameRules']}>
      <h1 className={styles['title']}>{t('infoFeaturesTitle')}</h1>
      <h1 className={styles['subtitle']}>{t('infoFeaturesSubtitleFS')}</h1>
      <div className={styles['row']}>
        {features.map((v) => (
          <p className={`${styles['p']}`} key={v.key} dangerouslySetInnerHTML={{ __html: v.text }} />
        ))}
      </div>
    </div>
  );
};

export default Features;
