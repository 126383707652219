import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { isMobile } from 'mobile-device-detect';
import AudioApi from '@money.energy/audio-api';
import { Popup } from '@money.energy/shared-components';
import { goToLobby } from '@money.energy/utils-fe';
import { ISongs } from '../../config';
import { eventEmitter } from '../../game/config';
import { EventTypes, PopupOpeningTypes } from '../../global.d';
import { setIsOpenMenuPopup, setIsPopupOpeningInProgress } from '../../gql/cache';
import type { IConfig } from '../../gql/d';
import { slotConfigGql } from '../../gql/query';
import History from '../History';
import Button from '../UI/Components/button';
import styles from './menu.module.scss';

const MenuIcon: React.FC<{
  setType: React.Dispatch<React.SetStateAction<'menu' | 'info' | 'settings' | 'history'>>;
  show: boolean;
}> = ({ setType, show }) => {
  const { t } = useTranslation();
  const handleHover = () => {
    AudioApi.play({ type: ISongs.UI_ButtonHover });
  };
  return (
    <div style={{ display: show ? 'block' : 'none' }}>
      <div className={styles['menu-wrap']}>
        <div
          className={styles['item']}
          onClick={() => {
            setType('history');
            AudioApi.play({ type: ISongs.UI_ButtonPress });
          }}
          onMouseEnter={handleHover}
        >
          <Button intent="history" onClick={() => {}} />
          {t('game_history')}
        </div>
        {isMobile && (
          <div
            className={styles['item']}
            onClick={() => {
              goToLobby();
              AudioApi.play({ type: ISongs.UI_ButtonPress });
            }}
          >
            <Button intent="lobby" onClick={() => {}} />
            {t('exit_to_lobby')}
          </div>
        )}
      </div>
    </div>
  );
};

const MenuComponent: React.FC = () => {
  const { data } = useQuery<IConfig>(slotConfigGql);
  const [showPopup, setShowPopup] = useState(false);
  const [type, setType] = useState<'menu' | 'info' | 'settings' | 'history'>('menu');
  const { isOpenMenuPopup } = data!;
  const handleClose = () => {
    if (
      setIsPopupOpeningInProgress() &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.BUY_BONUS &&
      setIsPopupOpeningInProgress() !== PopupOpeningTypes.MENU
    )
      return;

    setTimeout(() => {
      if (!AudioApi.isPlaying(ISongs.UI_ButtonPress) && !AudioApi.isPlaying(ISongs.UI_SpinStart)) {
        AudioApi.play({ type: ISongs.UI_ButtonClose });
      }
    }, 0);
    eventEmitter.emit(EventTypes.CLOSE_POPUP_BG);
    setIsOpenMenuPopup(false);
  };

  useEffect(() => {
    if (isOpenMenuPopup) {
      AudioApi.stop({ type: ISongs.UI_ButtonClose });
      AudioApi.play({ type: ISongs.UI_ButtonPress });
    }
    setType('menu');
    setShowPopup(isOpenMenuPopup);
  }, [isOpenMenuPopup]);

  useEffect(() => {
    if (showPopup && data?.isSpinInProgress) {
      setShowPopup(false);
      setIsOpenMenuPopup(false);
    }
  }, [data?.isSpinInProgress, showPopup]);

  return (
    <div className={styles['menu']}>
      <Popup id="popup" className="popup popup_menu" showNow={showPopup} setShowNow={handleClose}>
        <Button
          intent="close"
          className="popup__close"
          onClick={handleClose}
          onMouseOver={() => {
            if (!AudioApi.isPlaying(ISongs.UI_ButtonPress)) {
              AudioApi.play({ type: ISongs.UI_ButtonHover });
            }
          }}
        />
        <MenuIcon setType={setType} show={type === 'menu'} />
        <History setType={setType} show={type === 'history'} />
      </Popup>
    </div>
  );
};

export default MenuComponent;
