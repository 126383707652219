import AudioApi from '@money.energy/audio-api';
import { ISongs, SlotId } from '../../config';
import Game from '../../game';
import { eventEmitter, WinStages } from '../../game/config';
import IntroScreen from '../../game/introScreen/introScreen';
import { EnterProps, EventTypes, GameMode, ISettledBet, UserBonus } from '../../global.d';
import {
  setBetResult,
  setCurrentBonus,
  setCurrentBonusId,
  setFreeSpinsTotalWin,
  setGameHistory,
  setGameMode,
  setIsFreeSpinsWin,
  setIsPopupOpened,
  setIsProceedToGame,
  setIsSlotBusy,
  setIsSpinInProgress,
  setLastBetResult,
  setLastRegularWinAmount,
  setLastSpinData,
  setRestoreGame,
  setSlotConfig,
  setUserBalance,
  setWinAmount,
} from '../../gql/cache';
import client from '../../gql/client';
import { isStoppedGql } from '../../gql/query';
import { getBetResult, getSpinResult, getWinStage, playWinSound } from '../../utils';
import { States } from '../config';
import { Flow } from '../index';
import { AbstractModule } from './AbstractModule';

export class RegularModule extends AbstractModule {
  public gameMode: GameMode = GameMode.BASE_GAME;

  public static the = new RegularModule();

  private slotIdleTimeout: ReturnType<typeof setTimeout> | undefined;

  protected constructor() {
    super();
  }

  public override enterInitState(_prevState: States): void {
    Flow.the.changeState(States.INTRO);
    return;
  }

  public override exitInitState(nextState: States): void {
    if (nextState === States.INTRO) return;

    Game.initGame(setSlotConfig());
    eventEmitter.emit(EventTypes.FORCE_RESIZE);
    if (nextState === States.IDLE) {
      setIsProceedToGame(true);
    }
  }

  public override enterTransitionState(_prevState: States): void {
    eventEmitter.emit(EventTypes.TRANSITION_START);
  }

  public override exitTransitionState(_prevState: States): void {
    eventEmitter.emit(EventTypes.TRANSITION_END);
  }

  public override enterIntroState(_prevState: States): void {
    IntroScreen.initIntroScreen();
    eventEmitter.emit(EventTypes.FORCE_RESIZE);
    eventEmitter.once(EventTypes.HANDLE_DESTROY_INTRO_SCREEN, () => {
      if (setRestoreGame()) {
        Flow.the.changeState(States.RESTORE_GAME);
        return;
      }
      Flow.the.changeState(States.IDLE);
    });
  }

  public override exitIntroState(_nextState: States): void {
    Game.initGame(setSlotConfig());
    eventEmitter.emit(EventTypes.FORCE_RESIZE);
  }

  public override enterRestoreGameState(_prevState: States): void {
    setIsProceedToGame(true);
    if (!setCurrentBonusId()) {
      const bonus = setCurrentBonus();
      Game.the().onRestoreGame(bonus);
      Flow.the.changeState(States.TRANSITION);
      Flow.the.changeGameMode(bonus.gameMode, { bonus, immediate: true });
    } else {
      Flow.the.changeState(States.IDLE);
    }
  }

  public override enterIdleState(prevState: States): void {
    if (prevState === States.SPIN) {
      eventEmitter.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYINFO);
      eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);

      setIsSpinInProgress(false);
      setIsSlotBusy(false);
      client.writeQuery({
        query: isStoppedGql,
        data: {
          isSlotStopped: true,
        },
      });
      return;
    }
    if (prevState === States.TRANSITION) {
      setIsSpinInProgress(false);
      eventEmitter.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYINFO);
      if (setCurrentBonusId()) {
        return;
      }
    }

    if (prevState === States.INIT || prevState === States.INTRO) {
      // const debug = new Debug();
      // Flow.the.application.stage.addChild(debug);
      // Flow.the.application.ticker.add(() => debug.update());
      return;
    }
    this.slotIdleTimeout = setTimeout(() => {
      AudioApi.stop({ type: ISongs.Background });
      AudioApi.play({ type: ISongs.Ambiance });
    }, 20000);
    if (prevState === States.RESTORE_GAME) {
      const intervalId = window.setInterval(() => {
        if (eventEmitter.listenerCount(EventTypes.TOGGLE_SPIN) > 0) {
          setIsPopupOpened(false);
          eventEmitter.emit(EventTypes.TOGGLE_SPIN);
          window.clearInterval(intervalId);
        }
      }, 50);
      return;
    }
    eventEmitter.emit(EventTypes.SET_CURRENT_RESULT_MINI_PAYINFO);
    setIsSpinInProgress(false);
    setIsSlotBusy(false);
    eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);
    eventEmitter.emit(EventTypes.UPDATE_USER_BALANCE, getBetResult(setBetResult()).balance.settled);

    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: true,
      },
    });
    this.handleHistory(prevState === States.TRANSITION);
  }

  public override enterSpinState(_prevState: States): void {
    clearTimeout(this.slotIdleTimeout!);
    if (AudioApi.isPlaying(ISongs.Ambiance)) {
      AudioApi.stop({ type: ISongs.Ambiance });
      AudioApi.play({ type: ISongs.Background });
    }
    eventEmitter.emit(EventTypes.DISABLE_PAYINFO);
    eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, true);
    Game.the().spinSpinAnimation();
  }

  public override enterMysteryState(_prevState: States): void {
    eventEmitter.emit(EventTypes.MYSTERY_SYMBOLS_REVEAL_START);
    eventEmitter.once(EventTypes.MYSTERY_SYMBOLS_REVEAL_END, () => {
      Flow.the.changeState(States.BEFORE_WIN);
    });
  }

  public override enterBeforeWinState(_prevState: States): void {
    client.writeQuery({
      query: isStoppedGql,
      data: {
        isSlotStopped: false,
      },
    });
    const betResult = getBetResult(setBetResult());
    this.checkBaseWin(betResult);
  }

  public override enterHighlightWinState(_prevState: States): void {
    const betResult: ISettledBet = getBetResult(setBetResult());
    const { paylines } = betResult;
    const { winCoinAmount, spinResult } = betResult.bet.result;
    const scatterCount = spinResult.filter((e) => e.id === SlotId.SC1).length;

    if (getWinStage(winCoinAmount) >= WinStages.BigWin && scatterCount < 3) {
      eventEmitter.once(EventTypes.END_SPECIAL_WIN, () => {
        Flow.the.changeState(States.AFTER_WIN);
      });
      eventEmitter.emit(EventTypes.START_SPECIAL_WIN, winCoinAmount);
    } else {
      eventEmitter.once(EventTypes.WIN_LINE_ANIMATION_END, () => {
        if (getWinStage(winCoinAmount) >= WinStages.BigWin) {
          eventEmitter.once(EventTypes.END_SPECIAL_WIN, () => {
            Flow.the.changeState(States.AFTER_WIN);
          });
          setTimeout(() => {
            eventEmitter.emit(EventTypes.START_SPECIAL_WIN, winCoinAmount);
          }, 0);
        } else {
          Flow.the.changeState(States.AFTER_WIN);
        }
      });
      if (scatterCount < 3) {
        eventEmitter.emit(EventTypes.START_COUNT_UP, 0, winCoinAmount, 0);
      }
    }

    eventEmitter.emit(EventTypes.START_WIN_ANIMATION, betResult, paylines);
  }

  public override enterAfterWinState(_prevState: States): void {
    eventEmitter.emit(EventTypes.HIDE_COUNT_UP);
    const { winCoinAmount } = getBetResult(setBetResult()).bet.result;
    setWinAmount(winCoinAmount);
    setLastRegularWinAmount(winCoinAmount);
    setTimeout(() => Flow.the.changeState(States.JINGLE), 500);
  }

  public override enterJingleState(_prevState: States): void {
    const result = getBetResult(setBetResult());
    const isBonusWin = result.bet.data.bonuses.length > 0;
    const { winCoinAmount } = result.bet.result;
    playWinSound(winCoinAmount);

    if (isBonusWin) {
      const [bonus] = result.bet.data.bonuses;
      const isFreeSpinBonus = Boolean(!(bonus as UserBonus).data.freeSpinsFeature);

      if (isFreeSpinBonus) {
        setIsFreeSpinsWin(true);
        setCurrentBonus({
          ...(bonus as UserBonus),
          isActive: true,
          currentRound: 0,
        });
        setFreeSpinsTotalWin(result.bet.result.winCoinAmount);
        Flow.the.skipWinAnimation();
        Flow.the.changeState(States.TRANSITION);
        Flow.the.changeGameMode(GameMode.FREE_SPINS, {
          bonus: bonus as UserBonus,
        });
      }

      return;
    }
    Flow.the.changeState(States.IDLE);
  }

  public override enterModule(prevGameMode: GameMode, _props?: EnterProps): void {
    setGameMode(GameMode.BASE_GAME);
    AudioApi.play({ type: ISongs.Ambiance });
    eventEmitter.emit(EventTypes.DISABLE_BUY_BONUS_BTN, false);
    eventEmitter.on(EventTypes.HANDLE_BUY_BONUS, (bonusId: string) => {
      Flow.the.changeState(States.TRANSITION);
      Flow.the.changeGameMode(GameMode.BUY_BONUS, { bonusId });
    });
    if (prevGameMode === null) return;
    setIsFreeSpinsWin(false);
    if (prevGameMode === GameMode.FREE_SPINS) {
      setWinAmount(setFreeSpinsTotalWin());
    }
    Flow.the.changeState(States.IDLE);
  }

  public override exitModule(_nextGameMode: GameMode): void {
    clearTimeout(this.slotIdleTimeout!);
    AudioApi.stop({ type: ISongs.Ambiance });
    AudioApi.stop({ type: ISongs.Background });
  }

  public override setResult(result: ISettledBet): void {
    eventEmitter.emit(EventTypes.UPDATE_USER_BALANCE, result.balance.placed);
    setUserBalance({ ...setUserBalance(), balance: result.balance.placed });
    result.bet.result.spinResult = getSpinResult({
      reelPositions: result.bet.result.reelPositions,
      reelSet: result.bet.reelSet,
      icons: setSlotConfig().icons,
      mysteryMatrix: result.bet.data.features.mystery?.mysteryMatrix,
    });
    setBetResult(result);
    Flow.the.currentSpinResult = result.bet.result.spinResult;

    setLastBetResult(result.bet);
    setLastSpinData({
      layout: [],
      reelPositions: getBetResult(setBetResult()).bet.result.reelPositions,
    });
  }

  private handleHistory(_skipSoundChange: boolean): void {
    const betResult = getBetResult(setBetResult());
    const win = betResult.bet.result.winCoinAmount;
    const lastThreeSpins = [...setGameHistory().slice(1), !!win];

    setGameHistory(lastThreeSpins);
    setUserBalance({ ...setUserBalance(), balance: betResult.balance.settled });
  }

  private checkBaseWin(betResult: ISettledBet): void {
    const paylines = betResult.paylines;

    if (paylines.length) {
      Flow.the.changeState(States.HighlightWin);
    } else {
      Flow.the.changeState(States.IDLE);
    }
  }
}
